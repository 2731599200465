$linkpanel-gray: $gray-600;

.linkpanel {
  position: relative;
  display: block;
  color: $body-color;
  padding: ($grid-gutter-width / 2) 0;
  &:hover {
    color: $link-color;
    .linkpanel-label {
      color: $link-color;
    }
    svg {
      fill: $link-color;
    }
  }

  &-label {
    margin-bottom: 0.5rem;
    color: $linkpanel-gray;
    font-weight: $font-weight-bold;
  }

  ._street {
    display: flex;
    margin-bottom: 0;
    & > :last-child {
      flex-grow: 1;
      flex-shrink: 0;
    }
  }

  ._city {
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .btn-icon {
    position: absolute;
    margin: 0;
    top: 18px;
    right: 0;
    fill: $linkpanel-gray;
    width: 14px;
    height: 14px;
  }
}


.linkpanel-inline {
  display: inline-block;
  padding: 0;
  .linkpanel-label {
    margin: 0;
  }
  .btn-icon {
    top: 0.4em;
  }
  .btn-icon {
    position: static;
    margin: 0 0 5px 5px;
  }
}