$editList-icon-size: 1.25rem !default;

.editList {
  fill: $gray-600;
  svg {
    height: $editList-icon-size;
    width: $editList-icon-size;
  }
  &.is-valid, &.is-invalid {
    .editList-add-item {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0.5rem;
        display: block;
        height: (0.875rem * 1.5);
        width: 20px;
        margin-bottom: 0.5em;
      }
    }
  }
  &.is-valid {
    .editList-add-item {
      border: 1px solid $form-feedback-valid-color;
      &:after {
        background-image: $form-feedback-icon-valid;
      }
    }
  }
  &.is-invalid {
    .editList-add-item {
      border: 1px solid $form-feedback-invalid-color;
      &:after {
        background-image: $form-feedback-icon-invalid;
      }
    }
  }
}

//
// List Item
//
.editList-item {
  display: flex;
  align-items: stretch;
  margin-bottom: 0.5rem;
  fill: transparent;
  transition: fill 200ms ease-in-out;
  
  textarea {
    flex-grow: 1;
    max-height: calc(4.5rem + 1rem + 2px);
    border: 1px solid $gray-300;
    resize: none;
    &::placeholder {
      color: $gray-500;
    }
  }

  // Action Panel With "X" remove item
  &-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0;
    transition: width 250ms cubic-bezier(0.2, 0, 0, 1);
    svg {
      cursor: pointer;
      &:hover {
        fill: $danger;
        transition: fill 200ms ease-in-out;
      }
    }
  }

  &:hover,
  &:focus,
  &.is-focused {
    .editList-item-panel {
      width: 2.5rem;
      padding-left: 3px;
      fill: $gray-500;
      transition: fill 200ms ease-in-out, width 250ms cubic-bezier(0.2, 0, 0, 1);
    }
  }
}


//
// Button: Add Item
//
.editList-add-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  color: $gray-600;
  fill: $gray-600;
  background: $gray-100;
  cursor: pointer;
  svg {
    margin-right: 0.5rem;
  }
  &:hover {
    color: $primary;
    fill: $primary;
  }
}
