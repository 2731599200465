$toast-z-index: 2000;
$toast-height: 60px;
$toast-width: 460px;

.toast {
  position: fixed;
  z-index: $toast-z-index;
  top: 50px;
  left: calc(50% - #{($toast-width / 2)});
  display: flex;
  flex-wrap: nowrap;
  min-height: $toast-height;
  width: $toast-width;
  overflow: hidden;
  font-size: 1rem;
  
  &-header {
    z-index: $toast-z-index + 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: $toast-height;
    max-width: $toast-height;
    flex-shrink: 0;
    & > svg {
      height: ($toast-height * 0.7);
      width: ($toast-height * 0.7);
    }
  }
  
  &-body {
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    flex-grow: 1;
  }

  .btn-close {
    position: absolute;
    top: 0.75rem;
    right: 0.25rem;
    height: auto;
    width: auto;
    margin: 0;
    padding: 4px;
    border: 0;
    line-height: 1;
    background: none;
    & > svg {
      height: 0.875rem;
      width: 0.875rem;
      opacity: 0.8;
    }
    &:hover {
      & > svg {
        opacity: 1;
      }
    }
  }

  // Variations
  &-success, &-danger, &-info, &-warning {
    color: $white;
    fill: $white;
  }
  &-default {
    .toast-header {
      background: rgba(darken($white, 10%), 0.9);
    }
    .toast-body {
      background: rgba($white, 0.9);
    }
  }
  &-info {
    .toast-header {
      background: rgba(darken($info, 16%), 0.9);
    }
    .toast-body {
      background: rgba(darken($info, 8%), 0.9);
    }
  }
  &-warning {
    .toast-header {
      background: rgba(darken($warning, 12%), 0.9);
    }
    .toast-body {
      background: rgba(darken($warning, 2%), 0.9);
    }
  }
  &-success {
    .toast-header {
      background: rgba(darken($success, 10%), 0.9);
    }
    .toast-body {
      background: rgba($success, 0.9);
    }
  }
  &-danger {
    .toast-header {
      background: rgba(darken($danger, 10%), 0.9);
    }
    .toast-body {
      background: rgba($danger, 0.9);
    }
  }
}

@import './toasts_animations.scss'
