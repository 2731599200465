.editList-btnGroup {
  display: flex;
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    min-width: 30px;
    margin-bottom: 1px;
    padding: 0.5rem 0.125rem;
    line-height: 1;
    font-size: 0.75rem;
    font-weight: $font-weight-bold;
    border: 1px solid $border-color;
    border-right: none;
    background: $white;
    transition: background-color 150ms ease-in-out, border 150ms ease-in-out;

    &:last-child {
      border-right: 1px solid $border-color;
    }
    &:hover {
      color: $primary;
    }
    &.active {
      color: $white;
      background: $primary;
      border-color: $primary;
      transition: background-color 150ms ease-in-out, border 150ms ease-in-out;
    }

    @include media-breakpoint-up(sm) {
      min-width: 2rem;
    }
    @include media-breakpoint-up(md) {
      min-width: 2.25rem;
      padding: 0.5rem 0.5rem;
    }
  }
}

.editList-timeInputs {
  display: flex;
  height: 2rem;
}
.input-time {
  display: flex;
  font-weight: $font-weight-bold;
  border: 1px solid $border-color;
  & > input {
    width: 1.25rem;
    border: 0;
    outline: none;
    &:first-child {
      text-align: right;
    }
    @include media-breakpoint-up(sm) {
      width: 1.5rem;
    }
    @include media-breakpoint-up(md) {
      width: 2rem;
    }
  }
  .seperator {
    display: flex;
    align-items: center;
    margin: 0 1px;
    color: $gray-600;
  }
}