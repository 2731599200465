.joboffer-status {
  position: relative;
  padding-left: 1.5rem;
  font-weight: $font-weight-bold;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
  }

  &.joboffer-draft {
    &:before {
      background: $gray-500;
    }
  }
  &.joboffer-pending {
    &:before {
      background: $warning;
    }
  }
  &.joboffer-online {
    &:before {
      background: $success;
    }
  }
  &.joboffer-offline {
    &:before {
      background: $danger;
    }
  }
}