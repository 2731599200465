// TODO: default svg icon
.icon {
  width: $spacer * 1.25;
  height: $spacer * 1.25;
}

// Outline icon
.icon-outline {
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  padding: 2px;
}

// Icon Types
.icon-sm {
  width: $spacer * 1;
  height: $spacer * 1;
}
.icon-lg {
  width: $spacer * 2;
  height: $spacer * 2;
  &.icon-outline {
    padding: 4px;
  }
}

// Colors
@each $color, $value in $theme-colors {
  .icon-#{$color} {
    fill: $value;
    &.icon-outline {
      border-color: $value;
    }
  }
}

// Icon within link
a {
  & > svg {
    fill: $link-color
  }
  &:hover > svg {
    fill: $link-hover-color
  }
}


// .icon-muted {
//   fill: $icon-muted;
//   &.icon-outline {
//     border-color: $icon-muted;
//   }
// }