$page-header-height: 60px !default;
$page-header-padding: 0 30px !default;
$main-offset: 40px !default;

// Button reset
button:focus {
  outline: none;
}

.label-muted {
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
  color: $gray-600;
}


// Page Header Layout
.page-header {
  z-index: 100;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $page-header-padding;
  background-color: $primary;
  height: $page-header-height;
}

// Main Content Container
main {
  position: relative;
  height: 100%;
  padding-top: $page-header-height + $main-offset;
}