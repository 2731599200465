.text-template-item {
  padding: map-get($spacers, 2);
  margin-bottom: map-get($spacers, 1);
  background-color: $gray-100;
  &:hover {
    cursor: pointer;
    background-color: $gray-200;
  }
  &.active {
    background-color: rgba($info, .2);
    transition: background-color 200ms ease-out;
  }
}

.label-button {
  color: $primary;
  padding: 0;
  background: none;
  border: none;
}

.alert-outline-warning {
  color: darken($warning, 12%);
  fill: darken($warning, 5%);
  svg {
    height: 2.75rem;
    width: 2.75rem;
  }
}