// Description Column with fixed attributes
.sized-col {
  position: absolute;
  top: 61px;
  right: 24px;
  bottom: 95px;
  left: 24px;
  overflow: auto;
  // Textformat
  h1, h2, h3, h4, h5, h6, p, ul, ol, h1 + div, h2 + div, h3 + div, h4 + div, h5 + div, h6 + div {
    margin-top: 1rem;
    &:first-child {
      margin-top: 0;
    }
  }
}

// Textarea
#job-offer-description {
  min-height: 200px;
  //max-height: 648px;
}

.btn-sizing {
  transform: rotate(-90deg);
  position: absolute;
  top: 49px;
  right: -80px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 130px;
  padding: 8px 0;
  color: $text-muted;
  background: $gray-100;
  border: 1px solid $border-color;
  border-top: 0;
  cursor: pointer;
  &:hover, &:focus {
    color: $primary;
  }
}

.col-sizing {
  position: relative;
  flex-basis: 50%;
  max-width: 50%;
  overflow: hidden;
  will-change: flex-basis;
  transition: flex-basis 300ms cubic-bezier(0.2, 0, 0, 1) 0s, max-width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
  .col-static {
    height: 100%;
    background: $gray-100;
    color: $gray-600;
  }
}
